<template>
  <div class="text-center mt-3 mb-3">
    <div>Welcome from TIS Internal</div>
    <!-- <router-link :to="{ name: 'modules' }">
      <button
        type="button"
        class="btn btn-sm btn-blue waves-effect waves-light mb-2"
      >
        <i class="mdi mdi-arrow-right"></i>
        Click
      </button>
    </router-link> -->
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit("odoo/fetchAllEmployees");
  },
};
</script>